import React from 'react'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    button: {
        display: 'flex',
        // marginTop: 10,
        width: '100%',
        height: '100%'
    },
    nextSpan: {
        width: '100%',
        display: 'block'
    },
    backSpan: {
        display: 'inline-block',
        paddingRight: '8px'
    }
}))

const nextString = {
    english: "Next",
    tamil: "அடுத்த"
}

export const ButtonsNextBack = ({handleProceed, language="english", back, isNextDisabled}) => {
    const classes = useStyle()
    return <div className="button-next-back">
        <div className="d-flex" style={{flexDirection: 'row-reverse'}}>
            <span className={classes.nextSpan}><Button disabled={isNextDisabled} className={classes.button} variant="contained" onClick={handleProceed} color="primary">{nextString[language]}</Button></span>
            <span className={classes.backSpan}><Button className={classes.button} variant="contained" onClick={back} color="primary"><FontAwesomeIcon icon="long-arrow-alt-left"/></Button></span>
        </div>
    </div> 
}