import React, {useState} from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ButtonsNextBack } from './Buttons';



export const CityPage = ({setCity, proceed, defaultValue, back, language}) => {
    const [city, setCityState] = useState(defaultValue)

    const handleProceed = () => {
        setCity(city)
        proceed()
    }

    const cityList = [
        {
            label: "Singapore",
            value: "singapore"
        },
        {
            label: "Chennai",
            value: "chennai"
        },
        // {
        //     label: "Hyderabad",
        //     value: "hyderabad"
        // },
    ]

    const cityString = {
        english: "Choose your city",
        tamil: "உங்கள் நகரத்தைத் தேர்வுசெய்க"
    }

    const labelString = {
        english: {
            singapore: "Singapore",
            chennai: "Chennai"
        },
        tamil: {
            singapore: "சிங்கப்பூர்",
            chennai: "சென்னை"
        },
    }

    return <>
        <div className="info-div">
            <h3>{cityString[language]}</h3>
            <FormControl component="fieldset">
                <RadioGroup aria-label="language" name="gender1" value={city} onChange={(e) => setCityState(e.target.value)}>
                {
                cityList.map(({value}) =><FormControlLabel key={value} value={value} control={<Radio />} label={labelString[language][value]} />)
                }
                </RadioGroup>
            </FormControl>
        </div>
        {/* <span style={{flexGrow: 1}}></span> */}
        <ButtonsNextBack language={language} handleProceed={handleProceed} back={back}/>
    </>
}