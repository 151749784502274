import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {Formik} from 'formik'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import Chip from '@material-ui/core/Chip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {AlertDialog} from './Alert'
import { ButtonsNextBack } from './Buttons';

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginBottom: 10
    },
    button: {
        display: 'flex',
        marginTop: 10
    },
    dropzone: {
        marginBottom: 20,
        textAlign: 'middle',
    },
    thumbnail : {
        backgroundColor: '#f5f5f5',
        border: '2px dashed #dddddd',
        padding: '10px',
        cursor: 'pointer',
        textAlign: 'middle'
    },
    text : {
        color: "#666f7b",
        marginBottom: 20
    },
    chipDelete: {
        position: 'absolute',
        top: 0,
        right:0,
        color: "#FF4C5A",
        cursor: "pointer"
    },
    chip: {
        maxWidth: '100%'
    }
}))

export const AddressPage = ({proceed, setAddress, defaultAddress, setFile, setFileLink, defaultFile, defaultFileLink, back, language}) => {
    const classes = useStyle()

    const [localFileUrl, setLocalFileUrl] = useState(defaultFileLink);
    const [localFile, setLocalFile] = useState(defaultFile);

    const [isDialogOpen, setOpenDiaglog] = useState(false)

    const handleProceed = ({address1, address2, address3}) => {
        setAddress([address1, address2, address3])
        setFile(localFile)
        setFileLink(localFileUrl)
        proceed()
    }

    const deleteLocalFile = () => {
        //prevent memory leaks
        URL.revokeObjectURL(localFileUrl);
        setLocalFile(null);
        setLocalFileUrl(null);
      }
    
      const onDrop = (files) => {
        const file = files[0];
        // if (file.size / (1024 * 1024) > 2) {
        //   setOpenDiaglog(true)
        //   return;
        // }
        if (localFileUrl !== null) {
          URL.revokeObjectURL(localFileUrl)
        }
        const url = URL.createObjectURL(file);
        setLocalFile(file);
        setLocalFileUrl(`url("${url}")`)
      }

    const validationSchema = Yup.object().shape({
        address1: Yup.string()
            .required("Required"),
        address2: Yup.string()
            .required("Required"),
        address3: Yup.string()
            .required("Required"),
    })

    const previewStyle = {
        marginTop: 20,
        marginBottom: 20,
        background: localFileUrl,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        position: 'relative'
    }

    const headerString = {
        english: "Upload Proof Of Address",
        tamil: "முகவரி சான்று பதிவேற்ற"
    }

    const textString = {
        english: "Please make sure all information is clear and readable.",
        tamil: "எல்லா தகவல்களும் தெளிவாகவும் படிக்கக்கூடியதாகவும் இருப்பதை உறுதிப்படுத்தவும்."
    }

    const docString = {
        english: "Proof Of Address",
        tamil: "முகவரி சான்று"
    }

    const streetString = {
        english: "Street",
        tamil: "தெரு"
    }

    const cityString = {
        english: "City",
        tamil: "நகரம்"
    }

    const postCodeString = {
        english: "Postal Code",
        tamil: "அஞ்சல் குறியீடு"
    }


    return <Formik initialValues = {{address1: defaultAddress[0], address2: defaultAddress[1], address3: defaultAddress[2]}} onSubmit={handleProceed} validationSchema={validationSchema}>
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid}) => {
            
            return <>
            <div className="info-div">
                <h3>{headerString[language]}</h3>
                {/* <div className={classes.text}>Hang tight! Getting there.</div> */}
                <div className={classes.text}>{textString[language]}</div>
                <Dropzone onDrop={onDrop.bind(this)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                    <div
                        // style={inputStyle}
                        className={classes.dropzone}
                        {...getRootProps()}
                    >


                        <div className={localFile ? '' : classes.thumbnail}>
                            {localFile === null ?
                                <div className={localFile ? '' : 'text-center'}>
                                <input {...getInputProps()} />
                                <div><span>{docString[language]}</span></div>
                                <FontAwesomeIcon icon="file-alt"/>
                                {/* <div><small>Max 2MB</small></div> */}
                                </div>
                                : localFile.type.startsWith('image/') ? null :
                                <Chip
                                    className={classes.chip}
                                    label={localFile.name}
                                    onDelete={deleteLocalFile}
                                />
                            }
                        </div>
                    </div>
                    )}
                </Dropzone>
                {
                    localFileUrl !== null && localFile.type.startsWith('image') ? <div className="video-frame" style={previewStyle}>
                        <div
                            className={classes.chipDelete}
                            onClick={deleteLocalFile}
                        > <FontAwesomeIcon size="2x" icon="times" /> </div>
                        </div>: null
                }
                <TextField label={streetString[language]}
                    error={errors.address1 && touched.address1} 
                    helperText={errors.address1 && touched.address1 ? errors.address1 : ''}
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    name="address1"/>
                <TextField label={cityString[language]}
                    error={errors.address2 && touched.address2} 
                    helperText={errors.address2 && touched.address2 ? errors.address2 : ''}
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    name="address2"/>
                <TextField label={postCodeString[language]}
                    error={errors.address3 && touched.address3} 
                    helperText={errors.address3 && touched.address3 ? errors.address3 : ''}
                    value={values.address3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    name="address3"/>
                </div>
                {/* <span style={{flexGrow: 1}}></span> */}
                <ButtonsNextBack language={language} handleProceed={handleSubmit} back={back} isNextDisabled={!isValid || !localFile}/>
                <AlertDialog isOpen={isDialogOpen} toggle={() => setOpenDiaglog(false)} text="Please ensure your file size is < 2MB" />
            </>
        }}
    </Formik>
}