import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    text : {
        color: "#666f7b",
        marginBottom: 20
    }
}))

export const CongratsPage = ({language}) => {
    const classes = useStyle()

    const headerString = {
        english: "Congratulations! You are on the way to becoming one of our riders.",
        tamil: "வாழ்த்துக்கள்! நீங்கள் எங்கள் ரைடர்ஸில் ஒருவராக மாறுகிறீர்கள்."
    }

    const textString = {
        english: "Thank you for signing up with us. Please wait for our follow up SMS.",
        tamil: "எங்களுடன் பதிவு செய்ததற்கு நன்றி. எங்கள் SMS வரும்வரை காத்திருக்கவும்."
    }

    return <>
    <div className="info-div">    
        <h3>{headerString[language]}</h3>
        <div className={classes.text}>{textString[language]}</div>
    </div>
        </>
}


