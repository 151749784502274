import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import {ButtonsNextBack} from './Buttons'

// const useStyle = makeStyles(theme => ({
//     button: {
//         display: 'flex',
//         marginTop: 10,
//         width: '100%',
//         height: '100%'
//     },
//     nextSpan: {
//         width: '100%',
//         display: 'block'
//     },
//     backSpan: {
//         display: 'inline-block',
//         paddingRight: '8px'
//     }
// }))

export const LanguagePage = ({setLanguage, proceed, defaultValue, back}) => {
    // const classes = useStyle()
    const [language, setLanguageState] = useState(defaultValue)

    const handleProceed = () => {
        setLanguage(language)
        proceed()
    }

    const languageLists = [
        {
            label: "English",
            value: "english"
        },
        {
            label: "தமிழ்",
            value: "tamil"
        }
        // ,{
        //     label: "Bahasa",
        //     value: "bahasa"
        // },
    ]

    return <>
        <div className="info-div">
            <h3>Choose language</h3>
            <FormControl component="fieldset">
                <RadioGroup aria-label="language" name="gender1" value={language} onChange={(e) => setLanguageState(e.target.value)}>
                {
                languageLists.map(({value, label}) =><FormControlLabel key={value} value={value} control={<Radio />} label={label} />)
                }
                </RadioGroup>
            </FormControl>
        </div>
        {/* <span style={{flexGrow: 1}}></span> */}
        <ButtonsNextBack handleProceed={handleProceed} back={back}/>
    </>
}