import React, {useState} from 'react'
import YouTube from 'react-youtube';
import { ButtonsNextBack } from './Buttons'

// const useStyle = makeStyles(theme => ({
//     button: {
//         display: 'flex',
//     },
// }))

export const Video = ({videoId, proceed, defaultWatch, back, setWatchVid, language}) => {
    // const classes = useStyle()
    const [hasWatched, setWatched] = useState(defaultWatch)

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          rel: 0,
          playsinline: 1
        }
      };

    const headerString = {
      english: "Please watch this video before proceeding",
      tamil: "தொடர்வதற்கு முன் இந்த காணொளியைப் பாருங்கள்"
    }

    return <>
    <div className="info-div">    
        <h3>{headerString[language]}</h3>
            <YouTube
                className="video-frame"
                videoId={videoId}
                opts={opts}
                onEnd={() => {setWatchVid(true); setWatched(true)}}
            />
    </div>
            <span style={{flexGrow: 1}}></span>
            <ButtonsNextBack language={language} handleProceed={proceed} back={back} isNextDisabled={!hasWatched}/>
        </>
}


