import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {Formik} from 'formik'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import Chip from '@material-ui/core/Chip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {AlertDialog} from './Alert'
import { ButtonsNextBack } from './Buttons';

// import { removeExif } from '../utils'

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginBottom: 10
    },
    button: {
        display: 'flex',
        marginTop: 10
    },
    dropzone: {
        marginBottom: 20,
        textAlign: 'middle',
    },
    thumbnail : {
        backgroundColor: '#f5f5f5',
        border: '2px dashed #dddddd',
        padding: '10px',
        cursor: 'pointer',
        textAlign: 'middle'
    },
    text : {
        color: "#666f7b",
        marginBottom: 20
    },
    chipDelete: {
        position: 'absolute',
        top: 0,
        right:0,
        color: "#FF4C5A",
        cursor: "pointer"
    },
    chip: {
        maxWidth: '100%'
    }
}))

export const DocumentPage = ({proceed, setName, setDL, defaultName, defaultDLNumber, setFile, setFileLink, defaultFile, defaultFileLink, back, language}) => {
    const classes = useStyle()

    const [localFileUrl, setLocalFileUrl] = useState(defaultFileLink);
    const [localFile, setLocalFile] = useState(defaultFile);

    const [isDialogOpen, setOpenDiaglog] = useState(false)

    useEffect(() => {
        setLocalFile(defaultFile)
        setLocalFileUrl(defaultFileLink)
    }, [defaultFile, defaultFileLink])

    const handleProceed = ({name, DLNumber}) => {
        setName(name)
        setDL(DLNumber)
        setFile(localFile)
        setFileLink(localFileUrl)
        proceed()
    }

    const deleteLocalFile = () => {
        //prevent memory leaks
        URL.revokeObjectURL(localFileUrl);
        setLocalFile(null);
        setLocalFileUrl(null);
      }
    
      const onDrop = (files) => {
        const file = files[0];
        console.log(file)
        // if (file.size / (1024 * 1024) > 2) {
        //   setOpenDiaglog(true)
        //   return;
        // }
        if (localFileUrl !== null) {
          URL.revokeObjectURL(localFileUrl)
        }
        const url = URL.createObjectURL(file);
        // if (file.type === "image/jpeg"){
        //     const newFile = piexif.remove(file)
        //     const newURL = URL.createObjectURL(newFile)
        //     URL.revokeObjectURL(url)
        //     setLocalFile(newFile)
        //     setLocalFileUrl(`url("${newURL}")`)
        // }
        // else {
        setLocalFile(file);
        setLocalFileUrl(`url("${url}")`)
        // }
      }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Required"),
        DLNumber: Yup.string()
            .required('Required')
    })

    const previewStyle = {
        marginTop: 20,
        marginBottom: 20,
        background: localFileUrl,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        position: 'relative'
    }

    const headerString = {
        english: "Upload Driver's Licence (Front Side)",
        tamil: "ஓட்டுநர் உரிமத்தை பதிவேற்றுக (முன் பக்கம்)"
    }

    const textString = {
        english: "Please make sure all information is clear and readable.",
        tamil: "எல்லா ஆவணங்களும் தெளிவாகவும் படிக்கக்கூடியதாகவும் இருப்பதை உறுதிப்படுத்தவும்."
    }

    const docString = {
        english: "Driver's Licence (Front Side)",
        tamil: "ஓட்டுநர் உரிமம் (முன் பக்கம்)"
    }

    const nameString = {
        english: "Name",
        tamil: "பெயர்"
    }

    const DLString = {
        english: "Driving Licence Number",
        tamil: "ஓட்டுநர் உரிம எண்"
    }

    return <Formik initialValues = {{name: defaultName, DLNumber: defaultDLNumber}} onSubmit={handleProceed} validationSchema={validationSchema}>
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid}) => {
            
            return <>
            <div className="info-div">
                <h3>{headerString[language]}</h3>
                {/* <div className={classes.text}>You're almost there. Just a few more things to go!</div> */}
                <div className={classes.text}>{textString[language]}</div>
                <Dropzone onDrop={onDrop.bind(this)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                    <div
                        // style={inputStyle}
                        className={classes.dropzone}
                        {...getRootProps()}
                    >


                        <div className={localFile ? '' : classes.thumbnail}>
                        {localFile === null ?
                            <div className={localFile ? '' : 'text-center'}>
                            <input {...getInputProps()} />
                            <div><span> {docString[language]} </span></div>
                            <FontAwesomeIcon icon="file-alt"/>
                            {/* <div><small>Max 2MB</small></div> */}
                            </div>
                            : localFile.type.startsWith('image/') ? null :
                            <Chip
                                className={classes.chip}
                                label={localFile.name}
                                onDelete={deleteLocalFile}
                            />
                        }
                        </div>
                        
                    </div>
                    )}
                </Dropzone>
                        {
                            localFileUrl !== null && localFile.type.startsWith('image') ? <div className="video-frame" style={previewStyle}>
                                    <div
                                        className={classes.chipDelete}
                                        onClick={deleteLocalFile}
                                    > <FontAwesomeIcon size="2x" icon="times" /> </div>
                                </div>: null
                        }

                        <TextField label={nameString[language]}
                            error={errors.name && touched.name} 
                            helperText={errors.name && touched.name ? errors.name : ''}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.textField}
                            name="name"/>
                        <TextField label={DLString[language]}
                            error={errors.DLNumber && touched.DLNumber} 
                            helperText={errors.DLNumber && touched.DLNumber ? errors.DLNumber : ''}
                            value={values.DLNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.textField}
                            name="DLNumber"/>
                </div>
                {/* <span style={{flexGrow: 1}}></span> */}
                <ButtonsNextBack language={language} handleProceed={handleSubmit} back={back} isNextDisabled={!isValid || !localFile}/>
                <AlertDialog isOpen={isDialogOpen} toggle={() => setOpenDiaglog(false)} text="Please ensure your file size is < 2MB" />
            </>
        }}
    </Formik>
}