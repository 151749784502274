import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone'
import Chip from '@material-ui/core/Chip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {AlertDialog} from './Alert'
import { ButtonsNextBack } from './Buttons';

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginBottom: 10
    },
    button: {
        display: 'flex',
        marginTop: 10
    },
    dropzone: {
        marginBottom: 20,
        textAlign: 'middle',
    },
    thumbnail : {
        backgroundColor: '#f5f5f5',
        border: '2px dashed #dddddd',
        padding: '10px',
        cursor: 'pointer',
        textAlign: 'middle'
    },
    text : {
        color: "#666f7b",
        marginBottom: 20
    },
    chipDelete: {
        position: 'absolute',
        top: 0,
        right:0,
        color: "#FF4C5A",
        cursor: "pointer"
    },
    chip: {
        maxWidth: '100%'
    }
}))

export const DocumentBackPage = ({proceed, setFile, setFileLink, defaultFile, defaultFileLink, back, language}) => {
    const classes = useStyle()

    const [localFileUrl, setLocalFileUrl] = useState(defaultFileLink);
    const [localFile, setLocalFile] = useState(defaultFile);

    const [isDialogOpen, setOpenDiaglog] = useState(false)

    useEffect(() => {
        setLocalFile(defaultFile)
        setLocalFileUrl(defaultFileLink)
    }, [defaultFile, defaultFileLink])

    const handleProceed = () => {
        setFile(localFile)
        setFileLink(localFileUrl)
        proceed()
    }

    const deleteLocalFile = () => {
        //prevent memory leaks
        URL.revokeObjectURL(localFileUrl);
        setLocalFile(null);
        setLocalFileUrl(null);
      }
    
      const onDrop = (files) => {
        const file = files[0];
        // if (file.size / (1024 * 1024) > 2) {
        //   setOpenDiaglog(true)
        //   return;
        // }
        if (localFileUrl !== null) {
          URL.revokeObjectURL(localFileUrl)
        }
        const url = URL.createObjectURL(file);
        setLocalFile(file);
        setLocalFileUrl(`url("${url}")`)
      }

      const previewStyle = {
        marginTop: 20,
        marginBottom: 20,
        background: localFileUrl,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        position: 'relative'
    }

    const headerString = {
        english: "Upload Driver's Licence (Back Side)",
        tamil: "ஓட்டுநர் உரிமத்தை பதிவேற்றுக (பின் பக்கம்)"
    }

    const textString = {
        english: "Please make sure all information is clear and readable.",
        tamil: "எல்லா ஆவணங்களும் தெளிவாகவும் படிக்கக்கூடியதாகவும் இருப்பதை உறுதிப்படுத்தவும்."
    }

    const docString = {
        english: "Driver's Licence (Back Side)",
        tamil: "ஓட்டுநர் உரிமம் (பின் பக்கம்)"
    }


    return <>
    <div className="info-div">
        <h3>{headerString[language]}</h3>
        <div className={classes.text}>{textString[language]}</div>
        <Dropzone onDrop={onDrop.bind(this)} multiple={false}>
            {({ getRootProps, getInputProps }) => (
            <div
                // style={inputStyle}
                className={classes.dropzone}
                {...getRootProps()}
            >


                    <div className={localFile ? '' : classes.thumbnail}>
                        {localFile === null ?
                            <div className={localFile ? '' : 'text-center'}>
                            <input {...getInputProps()} />
                            <div><span>{docString[language]}</span></div>
                            <FontAwesomeIcon icon="file-alt"/>
                            {/* <div><small>Max 2MB</small></div> */}
                            </div>
                            : localFile.type.startsWith('image/') ? null :
                            <Chip
                                className={classes.chip}
                                label={localFile.name}
                                onDelete={deleteLocalFile}
                            />
                        }
                    </div>
                </div>
            )}
        </Dropzone>
        {
            localFileUrl !== null && localFile.type.startsWith('image') ? <div className="video-frame" style={previewStyle}>
                    <div
                        className={classes.chipDelete}
                        onClick={deleteLocalFile}
                    > <FontAwesomeIcon size="2x" icon="times" /> </div>
                </div>: null
        }
        </div>
        {/* <span style={{flexGrow: 1}}></span> */}
        <ButtonsNextBack language={language} handleProceed={handleProceed} back={back} isNextDisabled={!localFile}/>
        <AlertDialog isOpen={isDialogOpen} toggle={() => setOpenDiaglog(false)} text="Please ensure your file size is < 2MB" />
    </>
}