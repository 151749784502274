import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone'
import Chip from '@material-ui/core/Chip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {AlertDialog} from './Alert'
import { ButtonsNextBack } from './Buttons';

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginBottom: 10
    },
    button: {
        display: 'flex',
        marginTop: 10
    },
    dropzone: {
        marginBottom: 20,
        textAlign: 'middle',
    },
    thumbnail : {
        backgroundColor: '#f5f5f5',
        border: '2px dashed #dddddd',
        padding: '10px',
        cursor: 'pointer',
        textAlign: 'middle'
    },
    text : {
        color: "#666f7b",
        marginBottom: 20
    }
}))

export const AddressBackPage = ({proceed, setFile, setFileLink, defaultFile, defaultFileLink, back}) => {
    const classes = useStyle()

    const [localFileUrl, setLocalFileUrl] = useState(defaultFileLink);
    const [localFile, setLocalFile] = useState(defaultFile);

    const [isDialogOpen, setOpenDiaglog] = useState(false)

    const handleProceed = () => {
        setFile(localFile)
        setFileLink(localFileUrl)
        proceed()
    }

    const deleteLocalFile = () => {
        //prevent memory leaks
        URL.revokeObjectURL(localFileUrl);
        setLocalFile(null);
        setLocalFileUrl(null);
      }
    
      const onDrop = (files) => {
        const file = files[0];
        // if (file.size / (1024 * 1024) > 2) {
        //   setOpenDiaglog(true)
        //   return;
        // }
        if (localFileUrl !== null) {
          URL.revokeObjectURL(localFileUrl)
        }
        const url = URL.createObjectURL(file);
        setLocalFile(file);
        setLocalFileUrl(`url("${url}")`)
      }

      const previewStyle = {
        marginTop: 20,
        marginBottom: 20,
        background: localFileUrl,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'contain'
    }
    return <>
        <div className="info-div">
            <h3>Back Of Proof Of Address</h3>
            <div className={classes.text}>Please upload the back of your proof of address. Make sure all the information is clear enough to read.</div>
            <Dropzone onDrop={onDrop.bind(this)} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                <div
                    // style={inputStyle}
                    className={classes.dropzone}
                    {...getRootProps()}
                >


                    <div className={localFile ? '' : classes.thumbnail}>
                    {localFile === null ?
                        <div className={localFile ? '' : 'text-center'}>
                        <input {...getInputProps()} />
                        <div ><span>Back Of Proof of Address</span></div>
                        <FontAwesomeIcon icon="file-alt"/>
                        {/* <div><small>Max 2MB</small></div> */}
                        </div>
                        :
                        <Chip
                            label={localFile.name}
                            onDelete={deleteLocalFile}
                        />
                    }
                    </div>
                </div>
                )}
            </Dropzone>

            {
                localFileUrl !== null && localFile.type.startsWith('image') ? <div className="video-frame" style={previewStyle}/> : null
            }
        </div>
        {/* <span style={{flexGrow: 1}}></span> */}
        <ButtonsNextBack handleProceed={handleProceed} back={back} isNextDisabled={!localFile}/>
        <AlertDialog isOpen={isDialogOpen} toggle={() => setOpenDiaglog(false)} text="Please ensure your file size is < 2MB" />
    </>
}