import React from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {Formik} from 'formik'
import * as Yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactCountryFlag from "react-country-flag";
import { ButtonsNextBack } from './Buttons';

const useStyle = makeStyles(theme => ({
    textField: {
        display: 'flex',
        marginTop: 10,
    },
    button: {
        display: 'flex',
        // marginTop: 10,
        width: '100%',
        height: '100%'
    },
    nextSpan: {
        width: '100%',
        display: 'block'
    },
}))

export const GreetingPage = ({proceed, setPhoneNumber, defaultValue, back}) => {
    const classes = useStyle()

    const handleProceed = ({phoneNumber}) => {
        setPhoneNumber(phoneNumber)
        proceed()
    }

    const errorMsg = "Please enter a valid phone number"
    
    const phoneNumberSchema = Yup.object().shape({
        phoneNumber: Yup.string()
            .min(10, errorMsg)
            .max(10, errorMsg)
            .matches(/^[0-9]*$/, errorMsg)
            .required("Required")
    })

    return <Formik initialValues = {{phoneNumber: defaultValue}} onSubmit={handleProceed} validationSchema={phoneNumberSchema}>
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid}) => {
            
            return <>
                <div className="info-div">
                    <h3>Enter your phone number to begin</h3>
                    <TextField label="Phone number"
                        error={errors.phoneNumber && touched.phoneNumber} 
                        helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ''}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: <InputAdornment style={{padding: "6px 0 7px"}} position="start"><ReactCountryFlag styleProps={{
                                margin: "6px 0px 10px"
                            }} code="in" svg /><span style={{marginLeft: 10}}>+91</span></InputAdornment>,
                        }}
                        type="tel"
                        name="phoneNumber"/>
                </div>
                
                {/* <span style={{flexGrow: 1}}></span> */}
                <ButtonsNextBack isNextDisabled={!isValid} handleProceed={handleSubmit} back={back}/>
                
                
            </>
        }}
    </Formik>
}